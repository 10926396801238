import { type SupabaseClient } from '@supabase/supabase-js';
import { default as ky, HTTPError } from 'ky';
import { debounce } from 'perfect-debounce';
import toast from 'react-hot-toast';
import debugLog from '../models/debug';
import {
  Episode,
  EpisodeFromApi,
  Podcast,
  PodcastId,
  usePodcastsStore,
} from '../models/podcasts';
import { reportError } from './error-handling';
import { Modify } from './typescript';

// export const baseUrl = 'http://localhost:3000';
export const baseUrl = 'https://sh-api.adblockpodcast.com';
// export const baseUrl = 'https://cached-api.adblockpodcast.com';

const log = (...args: any[]) => {
  debugLog({ logName: 'api', logColor: 'gray' }, ...args);
};

// Refresh auth session if we get a 401 Unauthorized from API
const refreshAuthSessionDebounced = debounce(() => {
  log('Refreshing auth session');
  toast('Refreshing your session...');
  // @ts-expect-error _supabase global used to avoid circular dependency, sigh.
  return (window._supabase as SupabaseClient).auth
    .refreshSession()
    .then(({ data, error }) => {
      log('Refreshed session result', { data, error });
      if (error) {
        log('Failed to refresh session', error);
      }
    });
}, 100);

export function apiUrl(path: string, params = {}) {
  const url = new URL(`${baseUrl}/${path}`);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.append(key, value as string);
  });
  return url.toString();
}

export async function requestApi(url: string, postData?: object, headers?: object) {
  const api = headers
    ? ky.extend({
        hooks: {
          beforeRequest: [
            (req) =>
              Object.entries(headers).forEach(([key, value]) =>
                req.headers.set(key, value)
              ),
          ],
        },
      })
    : ky;

  try {
    const response = await (postData
      ? api.post(url, { json: postData })
      : api.get(url));
    const json = await response.json();
    return { json, response };
  } catch (error: unknown) {
    if (!(error instanceof HTTPError)) {
      log('API request failed', error);
    } else {
      let errorResponseJSON;
      let errorMessageFromAPI;
      try {
        errorResponseJSON = await error.response.json();
        errorMessageFromAPI = errorResponseJSON.error;
      } catch (err) {}
      log('API error', error.response.status, errorResponseJSON, error.message);

      // Refresh auth session if we get a 401 Unauthorized from API
      if (error.response.status === 401 && errorMessageFromAPI === 'Unauthorized') {
        refreshAuthSessionDebounced();
      }
      if (errorMessageFromAPI && typeof errorMessageFromAPI === 'string') {
        throw new APIError(
          `${errorMessageFromAPI} (Status = ${error.response.status})`
        );
      }
    }
    throw error;
  }
}

// export async function requestIFTTT(event: string, postData: object) {
//   const url = `https://maker.ifttt.com/trigger/${event}/json/with/key/i9zQ0bf2RxJV61dmJ55DiG_trs5h8KcI3cHfLIYl7iG`;
//   const api = ky.extend({
//     hooks: {
//       beforeRequest: [
//         (req) => (req.mode = 'no-cors'),
//         // req.mode = 'no-cors'
//         // Object.entries(headers).forEach(([key, value]) =>
//         //   req.headers.set(key, value)
//         // ),
//       ],
//     },
//   });

//   try {
//     return await (postData
//       ? api.post(url, { json: postData }).json()
//       : api.get(url).json());
//   } catch (error: any) {
//     if (error && error.response) {
//       let errorMessageFromAPI;
//       try {
//         const errorResponseJSON = await error.response.json();
//         errorMessageFromAPI = errorResponseJSON.error;
//       } catch (err) {}
//       if (errorMessageFromAPI && typeof errorMessageFromAPI === 'string') {
//         throw new APIError(
//           `${errorMessageFromAPI} (Status = ${error.response.status})`
//         );
//       }
//     }
//     throw error;
//   }
// }

export async function fetchPodcast(id: PodcastId) {
  const url = apiUrl(`podcast/${id}`);

  const { json } = await requestApi(url);
  const { podcast } = json as { podcast: Podcast };

  podcast.episodes = []; // Default value needs to be set
  // @ts-ignore Remove any properties added for debugging
  delete podcast.debug;
  usePodcastsStore.getState().updatePodcast(podcast);
  return podcast;
}

export async function fetchEpisodes(podcastId: PodcastId, cursorGuidHash = '') {
  const url = apiUrl(`podcast/${podcastId}/episodes`, {
    cursorGuidHash,
    limit: 20,
  });

  const { json, response } = await requestApi(url);
  const { episodes: episodesWithoutQueriedAt, hasMoreEpisodes } = json as {
    episodes: Omit<EpisodeFromApi, 'queriedAt'>[]; // .queriedAt must be derived from header
    hasMoreEpisodes: boolean;
  };

  // Derive .queriedAt property from Last-Modified header
  // .queriedAt will soon no longer be passed by API to improve caching duration
  const episodes = setQueriedAt(
    episodesWithoutQueriedAt,
    getLastModifiedSeconds(response)
  );

  if (!episodes.length) throw 'Episodes missing';

  const { podcasts, addOrUpdateEpisodes } = usePodcastsStore.getState();
  addOrUpdateEpisodes(
    podcastId,
    episodes.map((ep) => new Episode(ep, podcasts[podcastId])),
    cursorGuidHash
  );

  // console.log('fetched episodes', podcastId, episodes.length, cursorGuidHash);

  return {
    episodes,
    nextCursor: hasMoreEpisodes ? episodes[episodes.length - 1].guidHash : undefined,
  };
}

export type BumpPriorityAction =
  | 'listen_later'
  | 'download_episode'
  | 'play_episode'
  | 'view_episode';

export async function fetchEpisode(
  podcastId: PodcastId,
  episodeGuidHash: string,
  action?: BumpPriorityAction
) {
  const params = action ? { action } : undefined;
  const url = apiUrl(`podcast/${podcastId}/${episodeGuidHash}`, params);
  const { json, response } = await requestApi(url);
  let { episode: episodeWithoutQueriedAt, status } = json as {
    episode: Omit<EpisodeFromApi, 'queriedAt'>; // .queriedAt must be derived from header
    status?: string;
  };

  // Derive .queriedAt property from Last-Modified header
  // .queriedAt will soon no longer be passed by API to improve caching duration
  const [episode] = setQueriedAt(
    [episodeWithoutQueriedAt],
    getLastModifiedSeconds(response)
  );

  const { podcasts, addOrUpdateEpisode } = usePodcastsStore.getState();
  let podcast = podcasts[podcastId];

  // Fetch podcast if missing (in case of "Listening History page")
  if (!podcast) {
    await fetchPodcast(podcastId);
    podcast = usePodcastsStore.getState().podcasts[podcastId];
  }

  addOrUpdateEpisode(podcastId, new Episode(episode, podcast));

  return { episode, status };
}

function setQueriedAt(
  episodes: Modify<EpisodeFromApi, { queriedAt?: number }>[],
  lastModifiedSeconds?: number
) {
  // Eventually, API will stop setting .queriedAt property and it'll be derived from
  // the Last-Modified header instead.
  if (!lastModifiedSeconds) {
    lastModifiedSeconds =
      (episodes[0] && episodes[0].queriedAt) || Math.round(Date.now() / 1000);
    console.warn(`Missing Last-Modified for episodes`, episodes[0]);
  } else if (
    episodes[0] &&
    episodes[0].queriedAt &&
    Math.abs(episodes[0].queriedAt - lastModifiedSeconds) > 5
  ) {
    // Should never happen
    reportError(
      `Last-Modified doesn't match .queriedAt...: ${lastModifiedSeconds} !~ ${episodes[0].queriedAt}`,
      { ep: episodes[0] }
    );
  }
  episodes.forEach((ep) => {
    ep.queriedAt = lastModifiedSeconds;
  });
  return episodes as EpisodeFromApi[];
}

function getLastModifiedSeconds(response: Response) {
  const lastModifiedHeader = response.headers.get('Last-Modified');
  if (!lastModifiedHeader) {
    reportError('No Last-Modified header provided');
  }

  const lastModifiedSeconds = lastModifiedHeader
    ? new Date(lastModifiedHeader).getTime() / 1000
    : Math.round(Date.now() / 1000);

  const secondsAgo = Date.now() / 1000 - lastModifiedSeconds;
  if (secondsAgo < -0.1 || secondsAgo > 60 * 60 * 24 * 7) {
    // Should never happen
    reportError(
      `Weird last modified: ${lastModifiedHeader} Seconds = ${lastModifiedSeconds} Ago = ${secondsAgo}`,
      { url: response.url }
    );
  }

  return lastModifiedSeconds;
}

export async function fetchDonations() {
  const { json } = await requestApi(
    'https://didgjhjnevdixtjltmho.supabase.co/functions/v1/get-donations'
  );
  const { data } = json;
  return data as {
    totalUsedCredits: number;
    totalPaidCents: number;
    podcasts: {
      podcast: { id: string; name: string; artist: string };
      usedCredits: number;
      paidCents: number;
      donations: {
        receiptUrl: string;
        paidCents: number;
        date: string;
      }[];
    }[];
  };
}

export class APIError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'APIError';
  }
}
