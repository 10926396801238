import '@livelybone/request-idle-callback/lib/umd/polyfill';
import { h } from 'preact';
import { Route, Router, RouterOnChangeArgs } from 'preact-router';
import { useLayoutEffect, useState } from 'preact/hooks';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import debugLog from '../models/debug';
import { PodcastId, usePodcastsStore } from '../models/podcasts';
import { hideModal, useCurrentModal, useSessionStore } from '../models/session';
import prerenderUrls from '../prerender-urls';
import The404Page from '../routes/404';
import AccountPage from '../routes/account';
import AttributionPage from '../routes/attribution';
import BlankSSRPage from '../routes/blank-ssr-page';
import DeleteAccountPage from '../routes/delete-account';
import Downloads from '../routes/downloads';
import EpisodeRoute from '../routes/episode';
import ListeningHistory from '../routes/history';
import Home from '../routes/home';
import Library from '../routes/library';
import ListenNow from '../routes/now';
import PodcastRoute from '../routes/podcast';
import PricingPage from '../routes/pricing';
import Privacy from '../routes/privacy-policy';
import Search from '../routes/search';
import SignIn from '../routes/sign-in';
import SignUp from '../routes/sign-up';
import Terms from '../routes/terms-of-use';
import UpdatePassword from '../routes/update-password';
import { isPodcastIdentifier } from '../utils/helpers';
import { DeleteAccountDialog } from './delete-account-dialog';
import { FeedbackDialog } from './feedback-form';
import PlayerPanel from './player-panel';
import { SignInOrSignUpModal } from './sign-in-modal/sign-in-modal';
import { WelcomeModal } from './welcome-modal';

// Note: this file must be in the components folder. Otherwise we get this error:
// "Undefined component passed to createElement()"
// Details here: https://github.com/preactjs/preact-cli/issues/1286

const reactQueryClient = createReactQueryClient();
const nonAppUrls = ['/', '/privacy-policy', '/terms-of-use', '/pricing'];

const routeScrollPositions: Record<string, number> = {};
if (typeof history !== 'undefined' && history.scrollRestoration === 'auto') {
  history.scrollRestoration = 'manual';
}

const log = (...args: any[]) => {
  debugLog({ logName: 'router', logColor: 'deeppink' }, ...args);
};

const App = () => {
  const isInApp = useSessionStore((store) => store.isInApp);

  useLayoutEffect(() => {
    // Fix SSR issue where in-app class isn't added for some reason...
    const scrollContainer = document.getElementById('page-scroll-container');
    if (scrollContainer) scrollContainer.classList.toggle('in-app', isInApp);
  }, [isInApp]);

  const [handleRouteChange] = useState(() => setupHandleRouteChange());

  const currentModal = useCurrentModal();

  return (
    <QueryClientProvider client={reactQueryClient}>
      <div class="page-container">
        <Toaster position="top-right" />
        <div id="page-scroll-container" class={isInApp ? 'in-app' : ''}>
          <Router onChange={(data: RouterOnChangeArgs) => handleRouteChange(data)}>
            <Route path="/" component={Home} />
            <Route path="/now" component={ListenNow} />
            <Route path="/library" component={Library} />
            <Route path="/search" component={Search} />
            <Route path="/downloads" component={Downloads} />
            <Route path="/history" component={ListeningHistory} />
            <Route path="/podcast/:id" component={PodcastRoute} />
            <Route path="/podcast/:id/episode/:guidHash" component={EpisodeRoute} />
            <Route path="/sign-in" component={SignIn} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/update-password" component={UpdatePassword} />
            <Route path="/privacy-policy" component={Privacy} />
            <Route path="/attribution" component={AttributionPage} />
            <Route path="/terms-of-use" component={Terms} />
            <Route path="/blank-ssr-page" component={BlankSSRPage} />
            <Route path="/pricing" component={PricingPage} />
            <Route path="/pricing-in-app" component={PricingPage} />
            <Route path="/account" component={AccountPage} />
            <Route path="/delete-account" component={DeleteAccountPage} />
            <Route default component={The404Page} />
          </Router>
        </div>
        <PlayerPanel isHidden={!isInApp} />
        {currentModal === 'feedback' && <FeedbackDialog onClose={hideModal} />}
        {currentModal === 'sign-in' && <SignInOrSignUpModal onClose={hideModal} />}
        {currentModal === 'delete-account' && (
          <DeleteAccountDialog onClose={hideModal} />
        )}
        {currentModal === 'welcome' && <WelcomeModal onClose={hideModal} />}
      </div>
    </QueryClientProvider>
  );
};

function createReactQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // refetchOnWindowFocus: false,
        // refetchOnMount: false,
        // refetchOnReconnect: false,
        // notifyOnChangeProps: 'tracked',
        retry: 2, // instead of 3

        // Wait 20 seconds before ever re-fetching data
        staleTime: 1000 * 20,
      },
    },
  });
}

function setupHandleRouteChange() {
  let currentRoute = { navTime: -1, route: '' };

  if (typeof window !== 'undefined')
    // Handle case where I release and deploy a new version of the app, and the route chunks
    // change. Users on an older version will have their service worker update, delete outdated
    // route chunks and pre-cache the new route chunks, *but the app will still reference the
    // old chunks if they try and go to a route that they haven't already navigated to before*
    // In this case, just trigger a refresh to load the latest HTML that references the new chunks.
    addEventListener('unhandledrejection', function handleStaleRouteChunk(event) {
      if (
        Date.now() - currentRoute.navTime < 1000 &&
        event.reason &&
        event.reason.name === 'ChunkLoadError' &&
        event.reason.message.includes('/route-')
      ) {
        log('Failed to load chunk for route. Refreshing...');
        refreshOnce();
      }
    });

  return function handleRouteChange(args: RouterOnChangeArgs) {
    const { url, matches, previous } = args;

    log('Navigating to', url);
    currentRoute = { navTime: Date.now(), route: url };

    if (previous) {
      // Track & restore scroll position when navigating to new route
      const scrollContainer = document.getElementById('page-scroll-container');
      if (scrollContainer) {
        const scrollPosition = scrollContainer.scrollTop;
        routeScrollPositions[previous] = scrollPosition;
        // console.log('Route change', { previous, url, scrollPosition });
        restoreScrollPosition(scrollContainer, routeScrollPositions[url] || 0);
      }
    }

    // Remove query param added when forcing refresh on script load failure
    // It should really just be a temporary thing!
    if (url.includes('aslf=')) {
      setTimeout(() => {
        const url = new URL(window.location.href);
        if (url.searchParams.get('aslf')) {
          url.searchParams.delete('aslf');
          log('Removing ?aslf query param');
          history.replaceState({}, document.title, url.toString());
        }
      }, 0);
    }

    // Ignore query params in URL
    const simpleUrl = url.split(/[?#]/)[0];
    const isInApp = !nonAppUrls.includes(simpleUrl);

    if (typeof window === 'undefined') {
      useSessionStore.setState({ isInApp });
      return;
    }

    // Update page title
    const { title } = prerenderUrls.find((obj) => obj.url === simpleUrl) || {};
    if (title) document.title = title;

    // Keep track of what podcasts have been visited in this session, so that we keep track of them
    // in LocalStorage
    if (url.startsWith('/podcast') && matches && isPodcastIdentifier(matches.id)) {
      usePodcastsStore.getState().addSessionPodcastId(matches.id as PodcastId);
    }

    // Keep track of prior route in Session Store
    useSessionStore.setState({ previousRoute: previous || '', isInApp });
  };
}

function refreshOnce() {
  const url = new URL(window.location.href);
  if (!url.searchParams.get('refreshed')) {
    // On first failure, refresh the page and add ?refreshed=1 parameter
    log('Refreshing once...');
    url.searchParams.set('refreshed', '1');
    window.location.href = url.href + location.hash;
  } else {
    // Prevent refresh loop
    log('Already refreshed once. Not doing it again :(');
  }
}

function restoreScrollPosition(
  container: HTMLElement,
  amount: number,
  attemptCount = 1
) {
  const maxAttempts = 3;
  // @ts-ignore
  container.scrollTo({ top: amount, left: 0, behavior: 'instant' });
  const scrollTopAfterUpdate = container.scrollTop;
  if (Math.abs(scrollTopAfterUpdate - amount) > 10) {
    if (attemptCount >= maxAttempts) {
      return console.warn('Failed to restore scroll position');
    }
    requestAnimationFrame(() =>
      restoreScrollPosition(container, amount, ++attemptCount)
    );
  } else {
    // console.log('Restored scroll position', attemptCount, scrollTopAfterUpdate, amount);
  }
}

export default App;
